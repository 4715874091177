import React, { useState, useEffect } from 'react'
import { useNavigate, useOutletContext, useParams } from 'react-router-dom';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';

import { CategoryService } from '../services/category.service';
import { TheoryService } from '../services/theory.service';
import { OutletType } from './dashboard';
import { TheoryStruct } from './theories';
import useAuth from '../hooks/use-auth.hook';

const Theory: React.FC = () => {
    const navigate = useNavigate();
    const { id } = useParams();
    const { setTitle } = useOutletContext<OutletType>();
    const [categories, setCategories] = useState<{title: string, id: number}[]>([]);
    const [theory_title, setTheoryTitle] = useState('');
    const [body, setBody] = useState('');
    const [selected_category, setSelectedCategory] = useState('');
    const [first_last, setFirstLast] = useState(false);
    const auth = useAuth();

    useEffect(() => {
        CategoryService.getAll(auth.token).then((res) => {
            setCategories(res);
        });
        TheoryService.get(auth.token, +id!).then((res: TheoryStruct) => {
            setTheoryTitle(res.title);
            setBody(res.body);
            if(!res.category){
                setFirstLast(true);
            }
            else{
                setSelectedCategory(res.category.id.toString());
            }
        });
    }, [id]);

    useEffect(() => {
        setTitle('Թեմաներ');
    }, [setTitle]);

    function selectCategoryChange(event: SelectChangeEvent) {
        setSelectedCategory(event.target.value);
    }

    async function saveTheoryHandler(){
        if(theory_title !== '' && body !== '' && (selected_category !== '' || first_last)){
            const result = await TheoryService.edit(auth.token, +id!, theory_title, body, +selected_category);
            if(result.success){
                navigate(-1);
            }
        }
    }

    return (
        <div>
            <div className="wrapper">
                <Box sx={{ flexGrow: 1 }}>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <Stack spacing={1}>
                                <TextField label="Թեմայի անվանումը" value={theory_title} onChange={
                                    (event: React.ChangeEvent<HTMLInputElement>) => setTheoryTitle(event.currentTarget.value)
                                } variant="standard" required />
                                <TextField
                                    id="outlined-textarea"
                                    label="Տեքստ"
                                    placeholder="Տեքստ"
                                    value={body}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => setBody(event.currentTarget.value)}
                                    required
                                    multiline
                                />
                                {/* {
                                    first_last ? null : <FormControl required fullWidth>
                                        <InputLabel id="category-select-label">Ընտրել Գլուխը</InputLabel>
                                        <Select
                                            labelId="category-select-label"
                                            id="demo-simple-select"
                                            value={selected_category}
                                            label="Ընտրել Գլուխը"
                                            onChange={selectCategoryChange}
                                        >
                                            {
                                                categories.map((item) => {
                                                    return <MenuItem value={item.id} key={item.id}>{item.title}</MenuItem>;
                                                })
                                            }
                                        </Select>
                                    </FormControl>
                                } */}
                                <Button variant="contained" onClick={saveTheoryHandler}>Պահպանել</Button>
                            </Stack>
                        </Grid>
			<Grid item xs={6}>
                            <List>
                                <ListItem>
                                    <ListItemText
                                        primary={'Գլուխ'}
                                        secondary={categories.find(category => category.id.toString() === selected_category)?.title}
                                    />
                                </ListItem>
                            </List>
                        </Grid>
                    </Grid>
                </Box>
            </div>
        </div>
    );
}

export default Theory;
