import React, { useState } from 'react';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';

import useAuth from '../hooks/use-auth.hook';
import { Navigate } from 'react-router-dom';

const Login: React.FC = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState<string>();
    const auth = useAuth();

    function usernameInputHandler(event: React.ChangeEvent<HTMLInputElement>){
        setError(undefined);
	setUsername(event.currentTarget.value);
    }

    function passwordInputHandler(event: React.ChangeEvent<HTMLInputElement>){
        setError(undefined);
	setPassword(event.currentTarget.value);
    }

    async function buttonClickHandler(event: React.MouseEvent<HTMLButtonElement>){
        setError(undefined);
	try{
            await auth.onLogin(username, password, event);
        }
        catch(error: any){
            setError('Username or Password is incorrect');
        }
    }

    if(auth.token){
        return <Navigate to="/dashboard" replace />
    }

    return <Container maxWidth="xs">
        <Stack spacing={1}>
            <TextField label="username" variant="standard" value={username} onChange={usernameInputHandler} />
            <TextField label="password" variant="standard" type="password" value={password} onChange={passwordInputHandler}  />
            <Button variant="contained" onClick={buttonClickHandler}>Login</Button>
            {
                error !== undefined ? (
                    <Alert severity="error">{error}</Alert>
                ) : null
            }
	</Stack>
    </Container>;
}

export default Login;
