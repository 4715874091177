import React from 'react';
import { NavLink } from 'react-router-dom';
import Box from '@mui/material/Box';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import AccountCircle from '@mui/icons-material/AccountCircle';

import useAuth from '../hooks/use-auth.hook';

const Header: React.FC<{title: string}>= (props) => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [anchorEl1, setAnchorEl1] = React.useState<null | HTMLElement>(null);
    const auth = useAuth();

    function logoutHandler() {
        auth.onLogout();
    }

    const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMainMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl1(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
        setAnchorEl1(null);
    };

    return (
        <div className="header">
            <Box sx={{ flexGrow: 1 }}>
                <AppBar position="static">
                    <Toolbar>
                        <IconButton
                            size="large"
                            edge="start"
                            color="inherit"
                            aria-label="menu"
                            sx={{ mr: 2 }}
                            onClick={handleMainMenu}
                        >
                            <MenuIcon />
                        </IconButton>
                        <Menu
                                id="menu-appbar"
                                anchorEl={anchorEl1}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'left',
                                }}
                                keepMounted
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'left',
                                }}
                                open={Boolean(anchorEl1)}
                                onClose={handleClose}
                            >
                                <MenuItem onClick={handleClose}><NavLink to="categories">Գլուխներ</NavLink></MenuItem>
                                <MenuItem onClick={handleClose}><NavLink to="theories">Թեմաներ</NavLink></MenuItem>
                                <MenuItem onClick={handleClose}><NavLink to="quizzes">Հարցեր</NavLink></MenuItem>
                            </Menu>
                        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                            { props.title }
                        </Typography>
                        <div>
                            <IconButton
                                size="large"
                                aria-label="account of current user"
                                aria-controls="menu-appbar"
                                aria-haspopup="true"
                                onClick={handleMenu}
                                color="inherit"
                            >
                                <AccountCircle />
                            </IconButton>
                            <Menu
                                id="menu-appbar"
                                anchorEl={anchorEl}
                                anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                keepMounted
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                open={Boolean(anchorEl)}
                                onClose={handleClose}
                            >
                                <MenuItem onClick={logoutHandler}>Դուրս գալ</MenuItem>
                            </Menu>
                        </div>
                    </Toolbar>
                </AppBar>
            </Box>
        </div>
    );
}

export default Header;